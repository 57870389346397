<template>
    <div class="rounded shadow-lg">
        <div class="relative flex items-stretch justify-between rounded-t bg-white last:rounded-b">
            <div class="absolute inset-x-0 top-0 -mt-2 flex justify-center">
                <AspectBadge v-if="showPrivate" color="blue">
                    {{ t('Private') }}
                </AspectBadge>
            </div>

            <div class="flex grow items-center justify-between gap-4 p-4">
                <div class="flex flex-col">
                    <h4 class="line-clamp-3 text-base font-normal md:text-xl">
                        {{ name }}
                    </h4>
                </div>

                <div class="flex shrink-0 flex-col items-center gap-2 sm:flex-row">
                    <div class="flex shrink-0 items-center text-base font-normal md:text-xl">
                        {{ formatCurrency(offering.price) }}
                    </div>

                    <AspectInput
                        :max="totalLimit"
                        :min="0"
                        :model-value="quantity"
                        class="number-spin-none w-8 shrink-0 px-0 text-center"
                        type="number"
                        @update:model-value="value => setQuantity(toInteger(value))"
                    />
                </div>
            </div>

            <div class="isolate flex shrink-0 flex-col">
                <ReservationOfferingListItemButton
                    :class="[
                        'rounded-tr border-b border-l border-gray-200',
                        'hover:z-10',
                    ]"
                    :disabled="totalLimit !== null && quantity >= totalLimit"
                    :disabled-reason="t('Limit reached')"
                    @click="add(1)"
                >
                    <ChevronUpIcon class="size-5" />
                </ReservationOfferingListItemButton>

                <ReservationOfferingListItemButton
                    :class="[
                        'border-l border-gray-200',
                        !quantity && !description && 'rounded-br',
                    ]"
                    :disabled="quantity <= 0"
                    @click="remove(1)"
                >
                    <ChevronDownIcon class="size-5" />
                </ReservationOfferingListItemButton>
            </div>
        </div>

        <div
            v-if="description"
            class="border-t border-gray-200 bg-white p-2 text-left text-xs text-gray-600 last:rounded-b md:text-justify"
        >
            {{ description }}
        </div>

        <div
            v-if="quantity && showEntries"
            class="grid grid-cols-1 gap-4 rounded-b border-t border-gray-200 bg-gray-50 p-2 md:grid-cols-2 md:p-4"
        >
            <ReservationOfferingListItemEntry
                v-for="(entry, index) in filteredEntries"
                :key="index"
                :count="filteredEntries.length"
                :entry="entry"
                :entries="entries"
                :index="index"
                :number="index + 1"
                :can-invite-members="canInviteMembers"
                :can-invite-guests="canInviteGuests"
                :guests-maximum="guestsMaximum"
                :selected-slot-entries="selectedSlotEntries"
                :selected-slot="selectedSlot"
                @update="(value: object) => updateEntry(index, value)"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid';
    import { cloneDeep, toInteger } from 'lodash-es';

    import { formatCurrency } from '@aspect/shared/utils/number.ts';
    import { getTranslatedValue, t } from '@aspect/shared/plugins/i18n.ts';

    import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';
    import { useTicketOfficeStore } from '@aspect/ticket-office/stores/use-ticket-office-store.ts';

    import AspectInput from '@aspect/shared/components/aspect-input.vue';
    import AspectBadge from '@aspect/shared/components/aspect-badge.vue';

    import ReservationOfferingListItemButton from '@aspect/ticket-office/components/reservation-offering-list-item-button.vue';
    import ReservationOfferingListItemEntry from '@aspect/ticket-office/components/reservation-offering-list-item-entry.vue';

    import type { CreateEntryData, BundleData, SlotData, EntryData, MemberData } from '@aspect/shared/types/generated';
    import type { Offering, OfferingSlot } from '@aspect/shared/types/global';

    // PROPS & EMIT
    const props = withDefaults(defineProps<{
        offering: Offering;
        offeringType?: 'ticket' | 'bundle';
        selectedSlot: SlotData;
        canInviteMembers: boolean;
        canInviteGuests: boolean;
        guestsMaximum: number | null;
    }>(), {
        offeringType: 'ticket',
    });

    const pageProps = usePageProps();
    const store = useTicketOfficeStore();

    const entries = defineModel<CreateEntryData[]>('entries', { required: true });

    function entryIsSameOffering(entry: CreateEntryData) {
        const isSameOffering = entry.entryableId === props.offering.id;
        const isSameType = entry.entryableType === props.offeringType;

        return isSameOffering && isSameType;
    }


    // FILTERED ENTRIES
    const filteredEntries = computed(() => {
        return entries.value.filter(entryIsSameOffering);
    });

    function updateEntry(index: number, value: object) {
        const updatedEntries = [...filteredEntries.value];
        const otherTicketEntries = entries.value.filter((entry) => !entryIsSameOffering(entry));

        updatedEntries[index] = {
            ...updatedEntries[index],
            ...value,
        };

        updateEntries([...otherTicketEntries, ...updatedEntries]);
    }


    // SELECTED SLOT ENTRIES
    const selectedSlotEntries = computed(() => {
        return (props.selectedSlot.entries || []).reduce((entries: EntryData[], currentEntry) => {
            if (currentEntry.entryableType === 'bundle') {
                entries.push(...currentEntry.subEntries || []);
            } else {
                entries.push(currentEntry);
            }

            return entries;
        }, []);
    });


    // QUANTITY
    const quantity = computed(() => {
        return filteredEntries.value.length;
    });

    function setQuantity(quantity: number) {
        if (quantity <= 0) {
            quantity = 0;
        }

        if (quantity >= 600) {
            quantity = 600;
        }

        if (totalLimit.value !== null && quantity >= totalLimit.value) {
            quantity = totalLimit.value;
        }

        const existingEntries = entries.value.filter(entryIsSameOffering);
        const quantityToAddOrRemove = quantity - existingEntries.length;

        if (quantityToAddOrRemove > 0) {
            add(quantityToAddOrRemove);
        } else if (quantityToAddOrRemove < 0) {
            remove(quantityToAddOrRemove);
        }
    }


    // ADD
    function add(quantity: number) {
        const quantityToAdd = Math.abs(quantity);
        const newEntries: CreateEntryData[] = [];
        const subEntries: CreateEntryData[] = [];

        if (props.offeringType === 'bundle') {
            (props.offering as BundleData).tickets?.forEach((ticket) => {
                Array.from(Array(ticket.quantity)).forEach(() => {
                    const slot = getNextSlot(subEntries);

                    subEntries.push({
                        slotId: slot.id,
                        entryableId: ticket.ticketId,
                        entryableType: 'ticket',
                        firstName: null,
                        lastName: null,
                        inviteType: !props.canInviteMembers ? 'guest' : null,
                        customerId: null,
                        customerMembershipId: null,
                    });
                });
            });
        }

        Array.from(Array(quantityToAdd)).forEach(() => {
            const slot = getNextSlot(newEntries);

            newEntries.push({
                slotId: slot.id,
                entryableId: props.offering.id,
                entryableType: props.offeringType,
                firstName: null,
                lastName: null,
                inviteType: !props.canInviteMembers ? 'guest' : null,
                customerId: null,
                customerMembershipId: null,
                subEntries,
            });
        });

        autoSelectMember(newEntries);
        updateEntries([...entries.value, ...newEntries]);
    }

    function autoSelectMember(newEntries: CreateEntryData[]) {
        const member = pageProps.value.member;

        if (!member) {
            return;
        }

        const memberAlreadySelected = selectedSlotEntries.value.some((entry) => entry.customer?.id === member.id);
        const selectedTickets = entries.value.map(entry => entry.entryableType === 'bundle' ? entry.subEntries : entry).flat();
        const isEventOrFromMemberArea = props.selectedSlot.scheduleType === 'event' || store.memberBooking;
        const validEntries = newEntries.filter((entry) => {
            return props.offeringType !== 'bundle' || !!entry.subEntries?.length;
        });

        if (!requiresEntryName.value && store.memberBooking) {
            validEntries.forEach(entry => setMemberOnEntry(entry, member, false));
        }

        if (!memberAlreadySelected && !selectedTickets.length && isEventOrFromMemberArea) {
            setMemberOnEntry(validEntries[0], member, true);
        }
    }

    function setMemberOnEntry(entry: CreateEntryData | undefined, member: MemberData, onlyFirst: boolean) {
        if (!entry) {
            return;
        }

        if (props.offeringType === 'bundle' && entry.subEntries) {
            if (onlyFirst) {
                setMemberOnEntry(entry.subEntries[0], member, onlyFirst);
            } else {
                entry.subEntries.forEach(subEntry => setMemberOnEntry(subEntry, member, onlyFirst));
            }

            return;
        }

        entry.inviteType = 'member';
        entry.customerId = member.id;
        entry.customerMembershipId = store.selectedMembership?.id || null;
    }


    // REMOVE
    function remove(quantity: number) {
        const quantityToRemove = Math.abs(quantity);
        let removed = 0;

        const updatedEntries = [...entries.value].reverse().filter(entry => {
            if (entryIsSameOffering(entry) && removed < quantityToRemove) {
                removed++;
                return false;
            }

            return true;
        }).reverse();

        // Start from the end of the array and remove offerings with same id
        updateEntries(updatedEntries);
    }

    function updateEntries(updatedEntries: CreateEntryData[]) {
        entries.value = cloneDeep(updatedEntries);
    }


    // GET NEXT SLOT
    function getNextSlot(newEntries: CreateEntryData[]): OfferingSlot {
        const ticketEntries = [...filteredEntries.value, ...newEntries].reduce((tickets: CreateEntryData[], entry) => {
            const currentTickets = entry.entryableType === 'bundle' ? entry.subEntries || [] : [entry];

            return [...tickets, ...currentTickets];
        }, []);

        const lastEntry = ticketEntries[ticketEntries.length - 1];

        const previousSlotIndex = lastEntry ? props.offering.slots.findIndex((slot) => slot.id === lastEntry.slotId) : null;
        const nextSlotIndex = getNextValidSlotIndex(previousSlotIndex);

        return props.offering.slots[nextSlotIndex];
    }

    function getNextValidSlotIndex(previousSlotIndex: number | null): number {
        if (previousSlotIndex === null) {
            previousSlotIndex = -1;
        }

        let nextSlotIndex = previousSlotIndex + 1;

        if (nextSlotIndex >= props.offering.slots.length) {
            nextSlotIndex = 0;
        }

        const nextSlot = props.offering.slots[nextSlotIndex];

        if (!nextSlot || nextSlot.limit === 0) {
            return getNextValidSlotIndex(nextSlotIndex);
        }

        return nextSlotIndex;
    }


    // REQUIRES ENTRY NAME
    const requiresEntryName = computed(() => {
        return pageProps.value.division.settings.ticketingEntryName;
    });


    // SHOW ENTRIES
    const showEntries = computed(() => {
        if (!quantity.value) {
            return false;
        }

        if (!requiresEntryName.value) {
            return false;
        }

        if (props.offeringType === 'bundle') {
            return filteredEntries.value.some(entry => entry.subEntries?.length);
        }

        return true;
    });


    // SHOW PRIVATE
    const showPrivate = computed(() => {
        return props.offering.availability === 'private';
    });


    // NAME
    const name = computed(() => {
        return getTranslatedValue(props.offering.name);
    });


    // DESCRIPTION
    const description = computed(() => {
        return getTranslatedValue(props.offering.description);
    });


    // LIMITS
    const ticketLimits = computed(() => {
        const limitsMap = new Map<string, number | null>();

        props.offering.slots.forEach((slot) => {
            const currentLimit = limitsMap.get(slot.ticketId);

            if (currentLimit === undefined) {
                limitsMap.set(slot.ticketId, slot.limit);
            } else if (currentLimit !== null && slot.limit !== null) {
                limitsMap.set(slot.ticketId, currentLimit + slot.limit);
            } else {
                limitsMap.set(slot.ticketId, null);
            }
        });

        return limitsMap;
    });

    const totalLimit = computed(() => {
        const bundle = props.offering as BundleData;

        if (bundle.tickets) {
            const limits = bundle.tickets.map((bundleTicket) => {
                const ticketLimit = ticketLimits.value.get(bundleTicket.ticketId);

                if (ticketLimit === undefined || ticketLimit === null) {
                    return null;
                }

                return Math.floor(ticketLimit / bundleTicket.quantity);
            });

            return calculateTotalLimit(limits);
        }

        const limits = Array.from(ticketLimits.value.values());

        return calculateTotalLimit(limits);
    });

    function calculateTotalLimit(limits: (number | null)[]) {
        const validLimits = limits.filter(limit => limit !== null);

        if (validLimits.length === 0) {
            return null;
        }

        return Math.min(...validLimits);
    }
</script>
