<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        :id="section.section_id"
        :class="[
            'flex flex-col bg-white',
            classes.sectionPadding[globals.styles.spacing.value],
            classes.sectionGap[globals.styles.spacing.value],
        ]"
    >
        <div
            v-if="section.title"
            :class="[
                'container grid grid-cols-1',
                classes.contentGapHalf[globals.styles.spacing.value],
                classes.contentMarginBottomDouble[globals.styles.spacing.value],
            ]"
        >
            <h3 :class="['text-3xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.title }}
            </h3>
            <h4 v-if="section.sub_title" :class="['text-xl sm:text-2xl', classes.textAlignment[section.title_alignment.value]]">
                {{ section.sub_title }}
            </h4>
        </div>

        <div
            :class="[
                'container grid items-stretch',
                classes.layoutGrid[section.column_quantity.value],
                classes.contentGapDouble[globals.styles.spacing.value],
            ]"
        >
            <div
                v-for="product in section.products"
                :key="product.sku"
                :class="[
                    'flex flex-col',
                    classes.contentGapHalf[globals.styles.spacing.value]
                ]"
            >
                <figure
                    v-if="product.picture?.html"
                    :class="[
                        'overflow-hidden border border-gray-100',
                        '*:size-full *:object-cover',
                        classes.rounding[globals.styles.rounding.value],
                        classes.imageAspectRatio[section.image_aspect_ratio.value],
                        classes.imagePosition[section.image_position.value],
                        // product.frame && classes.imageFramed,
                    ]"
                    v-html="product.picture.html"
                />
                <figure
                    v-else
                    :class="[
                        'flex items-center justify-center overflow-hidden border border-gray-200 bg-gray-150',
                        classes.rounding[globals.styles.rounding.value],
                        classes.imageAspectRatio[section.image_aspect_ratio.value],
                        classes.imagePosition[section.image_position.value],
                        // product.frame && classes.imageFramed,
                    ]"
                >
                    <AspectIcon
                        class="size-1/2 text-gray-400"
                        name="shipping-box-2"
                    />
                </figure>

                <div
                    :class="[
                        'flex flex-1 flex-col justify-between',
                        classes.contentGap[globals.styles.spacing.value]
                    ]"
                >
                    <div :class="['flex flex-col', classes.contentGapHalf[globals.styles.spacing.value]]">
                        <div class="flex flex-col">
                            <div
                                v-if="product.brand"
                                :class="classes.textAlignment[section.text_alignment.value]"
                            >
                                <label class="text-xs font-bold tracking-wide text-gray-600">
                                    {{ product.brand }}
                                </label>
                            </div>
                            <h4 :class="['text-2xl', classes.textAlignment[section.text_alignment.value]]">
                                {{ product.name }}
                            </h4>
                            <p :class="['text-gray-700', classes.textAlignment[section.text_alignment.value]]">
                                {{ product.description }}
                            </p>
                        </div>
                        <div
                            v-if="section.show_price"
                            :class="['flex items-center justify-end', classes.contentGapHalf[globals.styles.spacing.value]]"
                        >
                            <strong
                                v-if="product.discount"
                                :class="[
                                    'bg-red-500 text-xs text-red-50',
                                    classes.rounding[globals.styles.rounding.value],
                                    classes.contentPaddingQuarter[globals.styles.spacing.value],
                                ]"
                            >
                                <template v-if="product.discountType === 'percentage'">
                                    - {{ formatPercent(product.discount / 100) }}
                                </template>
                                <template v-if="product.discountType === 'amount'">
                                    - {{ formatCurrency(product.discount) }}
                                </template>
                            </strong>
                            <del v-if="product.discount" class="text-xs">
                                {{ formatCurrency(product.price) }}
                            </del>
                            <samp class="font-sans text-2xl tabular-nums">
                                {{ formatCurrency(product.discountedPrice) }}
                            </samp>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="section.button" class="container flex justify-center">
            <SiteButton
                :disabled="section.button_disabled"
                :href="linkTypeHref(section)"
                :is-entry="section.button_link?.startsWith('/')"
                :target="section.button_target ? '_blank' : '_self'"
                :text="section.button_text"
            />
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { formatCurrency, formatPercent } from '@aspect/shared/utils/number.ts';
    import { useSite } from '@/site/composables/use-site.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    import SiteButton from '@/site/components/site-button.vue';

    import type { SiteProductData } from '@aspect/shared/types/generated';

    defineProps<{
        section: {
            products: SiteProductData[];
            [key: string]: any;
        };
    }>();

    const { classes, globals } = useSite();

    const linkTypeHref = (section) => {
        // Hides link if button is disabled
        if (section.button_disabled) {
            return;
        }

        // Files
        if (section.button_link_type.value === 'file') {
            return section.button_file.url;
        }

        // Email
        if (section.button_link_type.value === 'email' && section.button_email_address) {
            return section.button_full_name ? `mailto:${section.button_full_name}<${section.button_email_address}>` : `mailto:${section.button_email_address}`;
        }

        // Phone
        if (section.button_link_type.value === 'phone' && section.button_phone_number) {
            return `tel:${section.button_phone_number}`;
        }

        // Webpages
        if (section.button_link_type.value === 'webpage') {
            return `${section.button_link || ''}${section.button_fragment_identifier ? `#${section.button_fragment_identifier}` : ''}`;
        }
    };
</script>
