<template>
    <template v-if="createdReservation">
        <ReservationDisplay :reservation="createdReservation" />
    </template>

    <template v-else-if="!slotsLoading && slotStats.count === 0 || noOfferingsAvailable">
        <div class="flex flex-1 items-center justify-center">
            <AspectAlert class="flex-1" type="info">
                {{ t('No tickets available.') }}
            </AspectAlert>
        </div>
    </template>

    <template v-else-if="!isLoading">
        <ReservationSlotStep
            v-if="showSlotStep"
            v-model:selected-slot="selectedSlot"
            v-model:active-day="activeDay"
            v-model:selected-day="selectedDay"
            v-model:show-slots="showSlots"
            v-model:active-slot="activeSlot"
            class="rounded shadow-md"
            :loading="slotsLoading"
            :offering-step-loading="offeringStepLoading"
            :slots-by-day="slotsByDay"
            :auto-select-today="true"
        />

        <ReservationOfferingStep
            v-if="selectedSlot && showOfferingStep"
            v-model:entries="entries"
            :scroll="showSlotStep"
            :selected-slot="selectedSlot"
            :offerings="offerings"
            :show-slot-step="showSlotStep"
            @change="onChangeOffering"
        >
            <Transition
                enter-active-class="duration-300"
                enter-from-class="opacity-0 translate-y-4"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="duration-300"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-4"
            >
                <AspectButtonAttached
                    v-if="!showPayment"
                    class="w-full rounded-b border-t border-gray-200 lg:sticky lg:inset-x-0 lg:bottom-0"
                    color="black"
                    size="xl"
                    :color-hover-only="false"
                    :loading="paymentStepLoading"
                    :disabled="!!showPaymentButtonDisabledReason"
                    :disabled-reason="showPaymentButtonDisabledReason"
                    @click="onShowPayment"
                >
                    {{ t('Next') }}
                    <!-- @TODO Add Reserve or Payment depending on prepayment or not. -->
                    <!-- {{ t('Payment') }} -->
                </AspectButtonAttached>
            </Transition>
        </ReservationOfferingStep>

        <ReservationPaymentStep
            v-if="showPaymentStep"
            v-model:form="form"
            :entries="entries"
            :payment-amount="paymentAmount"
            :terms-and-conditions="termsAndConditions"
            :offerings-selected="offeringsSelected"
            :payment-methods="paymentMethods"
            :division="division"
            :initial-promo-code="promoCode"
            :all-entries-valid="allEntriesValid"
            :tickets-count="ticketsCount"
            @submit="submit"
            @change="onChangePayment"
        />

        <ReservationTimer
            :running="timerRunning"
            :limit="timerLimit"
            @ended="onTimerEnded"
        />
    </template>
</template>

<script lang="ts" setup>
    import { computed, onMounted, ref, watch, nextTick } from 'vue';
    import { useForm } from '@inertiajs/vue3';

    import { t } from '@aspect/shared/plugins/i18n.ts';

    import { useAxiosForm } from '@aspect/shared/composables/use-axios-form.ts';
    import { useRoute } from '@aspect/shared/composables/use-route.ts';
    import { useFeatures } from '@aspect/shared/composables/use-features.ts';
    import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';

    import { useTicketOfficeStore } from '@aspect/ticket-office/stores/use-ticket-office-store.ts';
    import { useReservationSlots } from '@aspect/ticket-office/composables/use-reservation-slots.ts';
    import { useReservationOfferings } from '@aspect/ticket-office/composables/use-reservation-offerings.ts';
    import { useReservationTimer } from '@aspect/ticket-office/composables/use-reservation-timer.ts';

    import AspectAlert from '@aspect/shared/components/aspect-alert.vue';
    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';

    import ReservationDisplay from '@aspect/ticket-office/components/reservation-display.vue';
    import ReservationTimer from '@aspect/ticket-office/components/reservation-timer.vue';
    import ReservationSlotStep from '@aspect/ticket-office/components/reservation-slot-step.vue';
    import ReservationOfferingStep from '@aspect/ticket-office/components/reservation-offering-step.vue';
    import ReservationPaymentStep from '@aspect/ticket-office/components/reservation-payment-step.vue';

    import type { Dayjs } from 'dayjs';
    import type {
        CreatePaymentData,
        CreateReservationData,
        DivisionData,
        PaymentMethodsData,
        ReservationData,
        ScheduleData,
        TranslatableData,
        SlotData,
        AmountData,
        ConsumerPaymentAmountRequest,
    } from '@aspect/shared/types/generated';


    // PROPS & EMIT
    const props = withDefaults(defineProps<{
        reservation: CreateReservationData;
        paymentMethods: PaymentMethodsData;
        termsAndConditions: TranslatableData;
        division: DivisionData;
        schedules: ScheduleData[];
        loading?: boolean;
        promoCode?: string | null;
    }>(), {
        promoCode: null,
    });

    const emit = defineEmits<{
        success: [];
        loading: [value: boolean];
    }>();

    const features = useFeatures();
    const store = useTicketOfficeStore();
    const { consumerRoute } = useRoute();

    const currentDivision = computed(() => props.division);
    const currentSchedules = computed(() => props.schedules);

    onMounted(() => {
        store.memberBooking = false;
        store.selectedMembership = null; // We don't care about the membership in the public booking.
    });


    // MEMBER
    const pageProps = usePageProps();
    const member = computed(() => pageProps.value.member);


    // SLOTS
    const {
        slotsByDay,
        selectedSlot,
        slotStats,
        slotStatsLoading,
        slotsLoading,
        activeDay,
        getInitialSlots,
        getSlotsForDay,
    } = useReservationSlots({
        division: currentDivision,
        schedules: currentSchedules,
    });

    const showSlotStep = computed(() => {
        return slotStats.count > 1;
    });

    onMounted(() => {
        getInitialSlots();
    });


    // OFFERINGS
    const {
        slotOfferings,
        slotOfferingsLoading,

        offerings,
        offeringsSelected,

        entries,
        allEntriesValid,
        ticketsCount,
    } = useReservationOfferings({
        division: currentDivision,
        selectedSlot,
        slotsByDay,
    });


    // CREATED RESERVATION
    const createdReservation = ref<ReservationData>();


    // FORM
    const form = useForm<CreateReservationData>(props.reservation);
    const axiosForm = useAxiosForm(form);

    async function submit(payment: CreatePaymentData) {
        if (!selectedSlot.value) {
            return;
        }

        form.processing = true;

        const data = form.data();

        try {
            const payload: CreateReservationData = {
                ...data,
                payment,
                entries: entries.value,
                startAt: selectedSlot.value.dateTime,
            };

            if (member.value && features.active('project:member-area-events')) {
                payload.customerId = member.value.id;
                payload.customer = null;
            }

            const response = await axiosForm.post(consumerRoute('/{division}/reservations', {
                division: props.division.id
            }), payload);

            if (response) {
                onSuccess(response.data.reservation);
            }
        } catch (error: any) {
            form.processing = false;

            if (selectedDay.value) {
                getSlotsForDay(selectedDay.value);
            }

            if (error.response?.status !== 400) {
                throw error;
            }
        }
    }

    function onSuccess(reservation: ReservationData | null) {
        if (!reservation) {
            return;
        }

        emit('success');

        createdReservation.value = reservation;
    }


    // SHOW PAYMENT
    const showPayment = ref(false);
    const paymentStepLoading = ref(false);

    async function onShowPayment() {
        paymentStepLoading.value = true;

        await loadPaymentAmount();

        showPayment.value = true;
        paymentStepLoading.value = false;
    }

    async function onChangePayment() {
        showPayment.value = false;

        await nextTick();

        const element = document.querySelector('#offering-step');

        element?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }

    const showPaymentStep = computed(() => {
        return selectedSlot.value && showOfferingStep.value && offeringsSelected.value && showPayment.value;
    });

    watch(offeringsSelected, (value) => {
        if (!value) {
            showPayment.value = false;
        }
    });


    // SECTION VISIBILITY
    const showOfferingStep = computed(() => {
        return !slotOfferingsLoading.value || !!slotOfferings.value;
    });

    function onChangeOffering() {
        showSlots.value = true;

        if (selectedDay.value)  {
            getSlotsForDay(selectedDay.value);
        }
    }


    // LOADING
    const isLoading = computed(() => {
        return slotStatsLoading.value && slotStats.count === 0;
    });

    watch(isLoading, (value) => {
        emit('loading', value);
    });

    const offeringStepLoading = computed(() => {
        return slotOfferingsLoading.value || lockLoading.value;
    });


    // NO TICKET AVAILABLE
    const noOfferingsAvailable = computed(() => {
        // Stil loading...
        if (slotOfferingsLoading.value || isLoading.value) {
            return false;
        }

        // Slot needs to be selected first...
        if (showSlotStep.value) {
            return false;
        }

        // If offerings are loading, verify if there's still capacity...
        if (offerings.value.length) {
            return !offerings.value.some(offering =>
                offering.slots.some(slot =>
                    slot.limit === null || slot.limit > 0
                )
            );
        }

        return true;
    });


    // ACTIVE SLOT
    const activeSlot = ref<SlotData | null>(null);


    // SHOW SLOTS
    const showSlots = ref(true);

    // SELECTED DAY
    const selectedDay = ref<Dayjs | null>(null);

    watch(selectedDay, (value) => {
        if (value) {
            getSlotsForDay(value);
        }
    });


    // TIMER
    const {
        timerLimit,
        timerRunning,
        lockLoading,
        onTimerEnded,
        reloadSlots,
    } = useReservationTimer({
        division: currentDivision,
        selectedSlot,
        showSlots,
        activeSlot,
    });

    watch(reloadSlots, (value) => {
        if (!value) {
            return;
        }

        if (selectedDay.value) {
            getSlotsForDay(selectedDay.value);
        }

        reloadSlots.value = false;
    });


    // PAYMENT AMOUNT
    const paymentAmount = ref<AmountData | null>(null);

    async function loadPaymentAmount() {
        const paymentAmountForm = useForm<ConsumerPaymentAmountRequest>({
            entries: entries.value,
            paymentMethod: form.payment.bypass ? null : form.payment.method,
            promoCodes: form.promoCodes,
            currency: 'CAD',
        });

        const route = consumerRoute('/{division}/payment-amount', {
            division: props.division.id
        });

        const response = await useAxiosForm(paymentAmountForm).post(route);

        if (response) {
            paymentAmount.value = response.data as AmountData;
        }
    }

    watch([entries, () => form.payment.method, () => form.promoCodes, () => form.payment.bypass], () => {
        if (!showPayment.value) {
            return;
        }

        loadPaymentAmount();
    });


    // SHOW PAYMENT BUTTON DISABLED
    const showPaymentButtonDisabledReason = computed(() => {
        if (!offeringsSelected.value) {
            return t('Please make your choice.');
        }

        if (!allEntriesValid.value) {
            return t('Please fill out all required fields.');
        }

        return '';
    });
</script>
