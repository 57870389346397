<template>
    <section
        :id="section.section_id"
        :class="[
            'flex flex-col justify-center bg-cover bg-no-repeat',
            globals.primary_nav.nav && globals.primary_nav.fixed ? 'min-h-[calc(50dvh_-_4.5rem)] md:min-h-[calc(50dvh_-_6rem)]' : 'min-h-[50dvh]',
            classes.bgPosition[section.background_image_position.value],
            classes.sectionPadding[globals.styles.spacing.value],
            section.background_overlay_color.value === 'darken' && 'bg-black text-gray-300',
            section.background_overlay_color.value === 'lighten' && 'bg-white text-gray-800',
            section.background_overlay_color.value === 'primary' && 'bg-tenant-500 text-tenant-text',
        ]"
        :style="[
            section.background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlayColorValue} ${backgroundOverlayOpacityValue}), rgba(${backgroundOverlayColorValue} ${backgroundOverlayOpacityValue})), url(${section.background_image.url});`,
        ]"
    >
        <div
            :class="[
                'container grid grid-cols-1',
                classes.contentGap[globals.styles.spacing.value]
            ]"
        >
            <div
                :class="[
                    'flex flex-col',
                    classes.contentGapHalf[globals.styles.spacing.value]
                ]"
            >
                <h3 v-if="section.title" :class="['text-4xl', classes.textAlignment[section.title_alignment.value]]">
                    {{ section.title }}
                </h3>
                <h4 v-if="section.sub_title" :class="['text-2xl', classes.textAlignment[section.title_alignment.value]]">
                    {{ section.sub_title }}
                </h4>
            </div>

            <div
                v-if="section.ctas.length"
                :class="[
                    'flex items-center',
                    classes.contentGapHalf[globals.styles.spacing.value],
                    ctaAlignement,
                ]"
            >
                <SiteButton
                    v-for="(cta, index) in section.ctas"
                    :key="index"
                    :href="linkTypeHref(cta)"
                    :is-entry="cta.button_link?.startsWith('/')"
                    :target="cta.button_target ? '_blank' : '_self'"
                    :text="cta.button_text"
                />
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { hexToRgb } from '@aspect/shared/utils/color.ts';
    import { useSite } from '@/site/composables/use-site.ts';
    import SiteButton from '@/site/components/site-button.vue';

    const props = defineProps<{
        section: any;
    }>();

    const { classes, globals } = useSite();

    const backgroundOverlayOpacityValue = computed(() => {
        const float = props.section.background_overlay_opacity / 100;
        return Math.round(float * 100) / 100;
    });

    const backgroundOverlayColorValue = computed(() => {
        const primaryRgb = hexToRgb('--tenant-color-500');

        return {
            darken: '0, 0, 0,',
            lighten: '255, 255, 255,',
            primary: `${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b},`,
        }[props.section.background_overlay_color.value];
    });

    const ctaAlignement = computed(() => {
        return {
            left: 'justify-start',
            center: 'justify-center',
            right: 'justify-end',
        }[props.section.title_alignment.value];
    });

    const linkTypeHref = (cta) => {
        // Hides link if button is disabled
        if (cta.button_disabled) {
            return;
        }

        // Files
        if (cta.button_link_type.value === 'file') {
            return cta.button_file.url;
        }

        // Email
        if (cta.button_link_type.value === 'email' && cta.button_email_address) {
            return cta.button_full_name ? `mailto:${cta.button_full_name}<${cta.button_email_address}>` : `mailto:${cta.button_email_address}`;
        }

        // Phone
        if (cta.button_link_type.value === 'phone' && cta.button_phone_number) {
            return `tel:${cta.button_phone_number}`;
        }

        // Webpages
        if (cta.button_link_type.value === 'webpage') {
            return `${cta.button_link || ''}${cta.button_fragment_identifier ? `#${cta.button_fragment_identifier}` : ''}`;
        }
    };
</script>
