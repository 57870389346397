<template>
    <label
        v-tippy="locked && lockedReason ? lockedReason : ''"
        :class="cn(
            'text-2xs flex items-center gap-2 font-semibold uppercase tracking-wide text-gray-600',
            props.class,
        )"
    >
        <slot />

        <AspectIcon
            v-if="locked"
            class="size-3"
            name="solid/padlock-square-1"
        />
        <AspectIcon
            v-if="required"
            class="size-3 animate-bounce"
            name="solid/asterisk-1"
        />
        <AspectIcon
            v-if="information"
            v-tippy="information"
            class="size-3 text-gray-500"
            name="solid/information-circle"
        />
    </label>
</template>

<script lang="ts" setup>
    import { directive as vTippy } from 'vue-tippy';

    import cn from '@aspect/shared/utils/cn.ts';

    import AspectIcon from './aspect-icon.vue';

    import type { HTMLAttributes } from 'vue';

    const props = withDefaults(defineProps<{
        required?: boolean;
        locked?: boolean;
        lockedReason?: string;
        information?: string,
        class?: HTMLAttributes['class']
    }>(), {
        required: false,
        locked: false,
    });
</script>
